import { z } from 'zod';
export const APIRouteSchema = z.object({
    path: z.string(),
    method: z.enum(['POST', 'DELETE', 'GET', 'PUT', 'ALL', 'PATCH', 'OPTION', 'HEAD']),
});
export const postApiKeyInputSchema = z.object({
    authorizedRoutes: z.array(APIRouteSchema),
    name: z.string().optional(),
});
export const postApiKeyOutputSchema = z.object({
    apiKey: z.string(),
});
export const apiKey = z.object({
    id: z.string(),
    prefix: z.string(),
    scope: z.object({
        authorizedRoutes: z.array(APIRouteSchema),
    }),
    algorithm: z.string(),
    createdAt: z.string(),
    name: z.string().optional(),
});
export const getApiKeysOutputSchema = z.object({
    apiKeys: z.array(apiKey),
});
export const deleteApiKeyOutputSchema = z.object({
    result: z.boolean(),
});
export const publicApiRoutes = [
    { path: '/public/communities/:subdomain/users/:id/xp', method: 'POST' },
    { path: '/communities/:subdomain/users/:id/xp', method: 'POST' },
    { path: '/public/communities/:subdomain/users/xp', method: 'POST' },
    { path: '/communities/:subdomain/users/xp', method: 'POST' },
    { path: '/public/communities/:subdomain/users/:id/xp', method: 'DELETE' },
    { path: '/communities/:subdomain/users/:id/xp', method: 'DELETE' },
    { path: '/public/communities/:subdomain/users/:id', method: 'GET' },
    { path: '/communities/:subdomain/users/:id', method: 'GET' },
    { path: '/public/communities/:subdomain/users', method: 'GET' },
    { path: '/communities/:subdomain/users', method: 'GET' },
    { path: '/public/communities/:subdomain/leaderboard', method: 'GET' },
    { path: '/communities/:subdomain/leaderboard', method: 'GET' },
    { path: '/public/communities/:subdomain/claimed-quests/review', method: 'POST' },
    { path: '/communities/:subdomain/claimed-quests/review', method: 'POST' },
    { path: '/public/communities/:subdomain/reviews', method: 'GET' },
    { path: '/communities/:subdomain/reviews', method: 'GET' },
    { path: '/public/communities/:subdomain/reviews/:id', method: 'GET' },
    { path: '/communities/:subdomain/reviews/:id', method: 'GET' },
    { path: '/public/communities/:subdomain/reviews/users/:id', method: 'GET' },
    { path: '/communities/:subdomain/reviews/users/:id', method: 'GET' },
    { path: '/public/communities/:subdomain/reviews/:id/events', method: 'GET' },
    { path: '/communities/:subdomain/reviews/:id/events', method: 'GET' },
    { path: '/public/communities/:subdomain/claimed-quests', method: 'GET' },
    { path: '/communities/:subdomain/claimed-quests', method: 'GET' },
    { path: '/public/communities/:subdomain/leaderboard/sprint', method: 'GET' },
    { path: '/communities/:subdomain/leaderboard/sprint', method: 'GET' },
    { path: '/public/communities/:subdomain/users/:userId/invites', method: 'GET' },
    { path: '/communities/:subdomain/users/:userId/invites', method: 'GET' },
    { path: '/public/communities/:subdomain/users/:userId/ban', method: 'POST' },
    { path: '/communities/:subdomain/users/:userId/ban', method: 'POST' },
    { path: '/public/communities/:subdomain/quests', method: 'GET' },
    { path: '/communities/:subdomain/quests', method: 'GET' },
    { path: '/public/communities/:subdomain', method: 'GET' },
    { path: '/communities/:subdomain', method: 'GET' },
    { path: '/public/communities/:subdomain/info', method: 'GET' },
    { path: '/communities/:subdomain/info', method: 'GET' },
    { path: '/public/communities/:subdomain/webhooks', method: 'POST' },
    { path: '/communities/:subdomain/webhooks', method: 'POST' },
    { path: '/public/communities/:subdomain/webhooks', method: 'GET' },
    { path: '/communities/:subdomain/webhooks', method: 'GET' },
    { path: '/public/communities/:subdomain/webhooks/:id', method: 'PATCH' },
    { path: '/communities/:subdomain/webhooks/:id', method: 'PATCH' },
    { path: '/public/communities/:subdomain/webhooks/:id', method: 'DELETE' },
    { path: '/communities/:subdomain/webhooks/:id', method: 'DELETE' },
    { path: '/public/communities/:subdomain/webhooks-event-types', method: 'GET' },
    { path: '/communities/:subdomain/webhooks-event-types', method: 'GET' },
    { path: '/public/communities/:subdomain/webhooks/:id/events', method: 'GET' },
    { path: '/communities/:subdomain/webhooks/:id/events', method: 'GET' },
    { path: '/public/communities/:subdomain/users/:id/referral-code', method: 'GET' },
    { path: '/communities/:subdomain/users/:id/referral-code', method: 'GET' },
    { path: '/public/communities/:subdomain/member', method: 'POST' },
    { path: '/communities/:subdomain/member', method: 'POST' },
    { path: '/public/communities/:subdomain/modules', method: 'GET' },
    { path: '/communities/:subdomain/modules', method: 'GET' },
    { path: '/public/communities/:subdomain/quests', method: 'POST' },
    { path: '/communities/:subdomain/quests', method: 'POST' },
    { path: '/public/communities/:subdomain/reviews', method: 'POST' },
    { path: '/communities/:subdomain/reviews', method: 'POST' },
    { path: '/public/communities/:subdomain/users/:userId/zealy_connect', method: 'DELETE' },
];
