import isURL from 'validator/lib/isURL';
import { z } from 'zod';
import { BLOCKCHAIN_NETWORKS } from './blockchain';
import { augmentedDiscordTaskSchema, augmentedNftTaskSchema, augmentedTelegramTaskSchema, augmentedTokenSchema, augmentedTokenTaskSchema, augmentedTweetReactTaskSchema, augmentedTwitterFollowTaskSchema, augmentedTwitterSpaceTaskSchema, baseApiTaskSchema, baseDateTaskSchema, baseDiscordTaskSchema, baseFileTaskSchema, baseId, baseInstructionSettingsSchema, baseInvitesTaskSchema, baseNFTTaskSchema, baseNumberTaskSchema, baseOnChainTaskSchema, baseOpinionTaskSchema, basePartnershipTaskSchema, basePollTaskSchema, baseQuestionTaskSettingsSchema, baseQuizTaskSchema, baseRewardMethodSchema, baseTelegramTaskSchema, baseTextTaskSchema, baseTiktokTaskSchema, baseTokenTaskSchema, baseTweetReactTaskSchema, baseTweetTaskSchema, baseTwitterFollowTaskSchema, baseTwitterSpaceTaskSchema, baseUrlTaskSchema, baseVisitLinkTaskSchema, categoryStatusSchema, conditionOperatorSchema, conditionSchema, discordTaskSchema, fileTaskSchema, identificationsApiTaskSchema, invitesTaskSchema, nftTaskSchema, nonNegativeIntSchema, onChainTaskSchema, opinionTaskSchema, partnershipTaskSchema, pollTaskSchema, questionImageTaskSettingsSchema, recurrenceSchema, retryAfterSchema, rewardNFTChainSchema, rewardOtherSchema, rewardRoleInputSchema, rewardRoleOutputSchema, rewardTokenSchema, rewardTypeSchema, rewardXPSchema, telegramTaskSchema, tiktokTaskSchema, tokenTaskSchema, tweetReactTaskSchema, tweetTaskSchema, twitterFollowTaskSchema, twitterSpaceTaskSchema, visitLinkTaskSchema, } from './common';
const baseRewardNFTSchema = z.object({
    type: z.literal(rewardTypeSchema.enum.nft),
    imageUrl: z.string().url(),
});
const rewardNFTSchema = baseRewardNFTSchema.extend({
    id: z.string(),
    maxSupply: nonNegativeIntSchema,
    totalSupply: nonNegativeIntSchema,
    totalClaimed: nonNegativeIntSchema,
    smartContractAddress: z.string(),
    raribleLink: z.string().url().optional(),
    symbol: z.string(),
    name: z.string().default('').nullish(),
    network: rewardNFTChainSchema,
    value: z.string(),
});
const augmentedReward = z.object({
    status: z.enum(['claimed', 'unspecified', 'claim-available', 'ended']),
    imageUrl: z.string().url().optional(),
});
const rewardSchema = augmentedReward
    .and(z.discriminatedUnion('type', [
    rewardXPSchema,
    rewardOtherSchema,
    rewardRoleOutputSchema,
    rewardNFTSchema,
    rewardTokenSchema.merge(augmentedTokenSchema),
]))
    .and(z.object({ method: baseRewardMethodSchema.optional() }));
const taskSchema = z.discriminatedUnion('type', [
    basePartnershipTaskSchema,
    baseOnChainTaskSchema,
    baseTokenTaskSchema,
    baseNFTTaskSchema,
    baseApiTaskSchema,
    baseTextTaskSchema,
    baseDiscordTaskSchema,
    baseUrlTaskSchema,
    baseTelegramTaskSchema,
    baseQuizTaskSchema,
    baseInvitesTaskSchema,
    baseVisitLinkTaskSchema,
    baseFileTaskSchema,
    baseDateTaskSchema,
    baseNumberTaskSchema,
    basePollTaskSchema,
    baseOpinionTaskSchema,
    baseTwitterFollowTaskSchema,
    baseTwitterSpaceTaskSchema,
    baseTweetReactTaskSchema,
    baseTweetTaskSchema,
    baseTiktokTaskSchema,
]);
const baseQuestSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    inReview: z.boolean(), // can a closed quest be in review ?
    rewards: z.array(rewardSchema),
    tasks: z.array(taskSchema),
    categoryId: z.string().uuid(),
    retryAfter: retryAfterSchema.nullable(),
    completed: z.boolean(),
    claimed: z.boolean(),
    claimLimit: z.number().min(0).default(0),
    canRetry: z.union([z.literal('never'), z.boolean()]),
    retryAt: z.string().datetime().optional(),
    position: z.number(),
    recurrence: recurrenceSchema,
});
const baseOpenedQuestSchema = z.object({
    opened: z.literal(true),
    retryAt: z.string().datetime().optional(),
});
const baseClosedQuestSchema = z.object({
    opened: z.literal(false),
    openAt: z.string().datetime().optional(),
});
const questLockSchema = z.object({
    locked: z.boolean(),
    conditions: z.array(z
        .object({
        fulfilled: z.boolean(),
    })
        .and(conditionSchema)),
    conditionOperator: conditionOperatorSchema,
});
const questOpenSchema = z.discriminatedUnion('opened', [
    baseOpenedQuestSchema,
    baseClosedQuestSchema,
]);
const baseQuestStatusSchema = baseQuestSchema.and(questLockSchema).and(questOpenSchema);
const baseCategorySchema = z.object({
    id: z.string().uuid(),
    title: z.string(),
    coverUrl: z.string().url().optional(),
    coverVerticalPosition: z.number(),
    color: z.string().optional(),
    totalQuestCount: z.number(),
    claimedQuestCount: z.number(),
    status: categoryStatusSchema,
    position: z.number(),
    quests: z.array(baseQuestStatusSchema),
});
const augmentedInvitesTaskSchema = z.object({
    context: z
        .object({
        invitedUserCount: nonNegativeIntSchema,
    })
        .optional(),
});
const baseAutoValidatedSettingsSchema = z.object({
    autoValidated: z.boolean(),
});
const apiTaskSchema = baseApiTaskSchema.extend({
    settings: baseInstructionSettingsSchema
        .extend({
        // used to determine whether the user can complete the task or not
        // or we do that only on claim on the backend
        identifications: identificationsApiTaskSchema,
        network: z.enum(BLOCKCHAIN_NETWORKS).optional(),
        redirectUrl: z.string().optional(),
    })
        .refine(data => !(data.identifications?.includes('zealy-connect') && !isURL(data.redirectUrl ?? '')), {
        message: 'Required if Zealy Connect is enabled',
        path: ['redirectUrl'],
    }),
});
const textTaskSchema = baseTextTaskSchema.extend({
    settings: baseInstructionSettingsSchema.and(baseAutoValidatedSettingsSchema),
});
const urlTaskSchema = baseUrlTaskSchema.extend({
    settings: baseInstructionSettingsSchema.and(baseAutoValidatedSettingsSchema),
});
const dateTaskSchema = baseDateTaskSchema.extend({
    settings: baseInstructionSettingsSchema.and(baseAutoValidatedSettingsSchema),
});
const numberTaskSchema = baseNumberTaskSchema.extend({
    settings: baseInstructionSettingsSchema.and(baseAutoValidatedSettingsSchema),
});
const quizTaskSchema = baseQuizTaskSchema.extend({
    settings: baseQuestionTaskSettingsSchema.and(questionImageTaskSettingsSchema),
});
const augmentedTaskSchema = z.intersection(baseId, z.discriminatedUnion('type', [
    apiTaskSchema,
    partnershipTaskSchema,
    onChainTaskSchema,
    textTaskSchema,
    urlTaskSchema,
    quizTaskSchema,
    visitLinkTaskSchema,
    fileTaskSchema,
    dateTaskSchema,
    numberTaskSchema,
    pollTaskSchema,
    opinionTaskSchema,
    tweetTaskSchema,
    tiktokTaskSchema,
    tokenTaskSchema.merge(augmentedTokenTaskSchema),
    nftTaskSchema.merge(augmentedNftTaskSchema),
    invitesTaskSchema.merge(augmentedInvitesTaskSchema),
    twitterSpaceTaskSchema.merge(augmentedTwitterSpaceTaskSchema),
    tweetReactTaskSchema.merge(augmentedTweetReactTaskSchema),
    telegramTaskSchema.merge(augmentedTelegramTaskSchema),
    discordTaskSchema.merge(augmentedDiscordTaskSchema),
    twitterFollowTaskSchema.merge(augmentedTwitterFollowTaskSchema),
]));
export const getQuestOutputSchema = baseQuestSchema
    .extend({
    description: z.object({}).nullish(),
    rewards: z.array(augmentedReward.and(rewardSchema)),
    tasks: z.array(augmentedTaskSchema),
    claimCount: z.number().min(0).optional(),
})
    .and(questLockSchema)
    .and(questOpenSchema);
const getReferralLinkSchema = z.object({
    id: z.string(),
});
export const getCategoryOutputSchema = baseCategorySchema.extend({
    description: z.string().optional(),
    coverUrl: z.string().url().optional(),
    color: z.string().optional(),
    position: z.number(),
});
export const questContributorFiltersSchema = z.enum([
    'locked',
    'available',
    'inCooldown',
    'inReview',
    'completed',
    'claimed',
]);
export const baseRewardInputSchema = z.discriminatedUnion('type', [
    rewardXPSchema,
    rewardOtherSchema,
    rewardRoleInputSchema,
    rewardTokenSchema,
]);
export const getQuestboardOutputSchema = z.array(baseCategorySchema);
